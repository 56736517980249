import { ComponentFactoryConstants } from './../../../adapters/helpers/Constants';
class Document {

    constructor(props) {
        this.props = props;
    }

    getFieldValue(fieldName) {
        const value = this.getFields()[fieldName];
        return value;
    }

    setFieldValue(propName, propValue) {
        this.props[propName] = propValue;
    }

    getFields() {
        let fields = this.getDocument().fields;
        if (!fields) {
            fields = {};
        }
        return fields;
    }

    getDocument() {
        let doc = this.props?.document;

        if (doc == null) {
            doc = this.props;
        }

        if (!doc) {
            doc = {};
        }
        if (doc) {
            this.handleLazyLoadDoc(doc);
        }
        return doc;
    }
    handleLazyLoadDoc(doc) {
        let bodyBlock = [];
        let bodyBlocks = doc?.fields?.blocks?.[0]?.fields?.blocks || [];
        if ((bodyBlocks.length > 0 && bodyBlocks?.[1]?.fields?.code === 'body')) {
            bodyBlock = bodyBlocks?.[1]?.fields?.blocks;
            if (bodyBlock && bodyBlock.length > 0) {
                bodyBlock.map((child, index) => {
                    if (child?.fields) {
                        let exceptCType = [ComponentFactoryConstants.LegacyProductHighlightBlock, ComponentFactoryConstants.ProductHighlights];
                        child.fields['isLazy'] = (index == 0 || exceptCType.includes(child?.fields?.['contentType']) ? false : true)
                    }
                })
            }
        }
        if ((bodyBlocks.length > 0 && bodyBlocks?.[2]?.fields?.code === 'after-body')) {
            bodyBlock = bodyBlocks?.[2]?.fields?.blocks;
            if (bodyBlock && bodyBlock.length > 0) {
                bodyBlock.map((child, index) => {
                    if (child?.fields) {
                        child.fields['isLazy'] = true;
                    }
                })
            }
        }
    }
}

export default Document